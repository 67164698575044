<template>
  <div>
    <FullCalendar :options="calendarOptions" />
    <Dialog
      v-model:visible="dialogItemDetailsVisible"
      header="Item Details"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '80vw' }"
      :modal="true"
    >
      <div v-if="dialogItemDetails">
        <DataTable :value="dialogItemDetails.details" :scrollable="true">
          <template #empty>
            <div class="text-center">Tidak ada data.</div>
          </template>
          <template #loading>
            <div class="text-center">Memuat data, harap tunggu...</div>
          </template>

          <Column
            field="item.barcode_1"
            header="Barcode"
            style="min-width: 8rem; background-color: #f8f9fa"
          />
          <Column
            field="item.name"
            header="DESKRIPSI"
            style="min-width: 12rem; background-color: #f8f9fa"
          />
          <Column
            field="harga"
            header="Harga Normal"
            style="
              min-width: 12rem;
              max-width: 12rem;
              justify-content: flex-end;
              background-color: #f8f9fa;
            "
          >
            <template #body="slotProps">
              <span style="text-align: right">
                {{ formatCurrency(slotProps.data.harga) }}
              </span>
            </template>
          </Column>
          <Column
            field="disc"
            header="Potongan/DISC(%)"
            style="
              min-width: 12rem;
              max-width: 12rem;
              justify-content: flex-end;
              background-color: #f8f9fa;
            "
          >
            <template #body="slotProps">
              <span style="text-align: right">
                {{
                  slotProps.data.disc > 100
                    ? formatCurrency(slotProps.data.disc)
                    : `${slotProps.data.disc}%`
                }}
              </span>
            </template>
          </Column>
          <Column
            field="harga_promo"
            header="Harga Promo"
            style="
              min-width: 12rem;
              max-width: 12rem;
              justify-content: flex-end;
              background-color: #f8f9fa;
            "
          >
            <template #body="slotProps">
              <span style="text-align: right">
                {{ formatCurrency(slotProps.data.harga_promo) }}
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </Dialog>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import idLocale from '@fullcalendar/core/locales/id'
import { formatCurrency } from '@/helpers/index'

export default {
  name: 'PromoCalendar',
  components: {
    FullCalendar,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      dialogItemDetailsVisible: false,
      dialogItemDetails: null,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: '',
        },
        aspectRatio: 3.5,
        initialView: 'dayGridMonth',
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        locale: idLocale,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        events: [],
      },
    }
  },
  watch: {
    items: {
      immediate: true,
      handler(newItems) {
        this.updateCalendarEvents(newItems)
      },
    },
  },
  methods: {
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },
    updateCalendarEvents(items) {
      this.calendarOptions.events = items.map((event) => ({
        title: event.deskripsi,
        start: this.formatDateToShort(event.start_at),
        end: this.formatDateToShort(event.end_at),
        backgroundColor: this.getEventColor(event.status),
        borderColor: this.getEventBorderColor(event.status),
        extendedProps: {
          details: event.details,
          deskripsi: event.deskripsi,
        },
      }))
    },
    getEventColor(statusPromo) {
      switch (statusPromo) {
        case 'ongoing':
          return 'green'
        case 'expired':
          return 'orange'
        case 'planning':
          return 'grey'
        default:
          return 'blue'
      }
    },
    getEventBorderColor(statusPromo) {
      switch (statusPromo) {
        case 'ongoing':
          return 'darkgreen'
        case 'expired':
          return 'darkorange'
        case 'planning':
          return 'darkgrey'
        default:
          return 'darkblue'
      }
    },
    formatDateToShort(value) {
      return value.split('T')[0]
    },
    handleEventClick(info) {
      const event = info.event
      const deskripsi = event.extendedProps.deskripsi
      const details = event.extendedProps.details

      this.dialogItemDetails = {
        deskripsi: deskripsi,
        details: details,
      }

      this.dialogItemDetailsVisible = true
    },
  },
}
</script>
