<template>
  <Calendar
    v-model="value"
    dateFormat="dd/mm/yy"
    @date-select="mergeDate()"
    @clear-click="clearDate()"
  />
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    modelValue: {
      type: String,
      default() {
        return null
      },
    },
    showButtonBar: {
      type: Boolean,
      default: false,
    },
    numberOfMonths: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      oldValue: null,
      value: null,
      clearUpdate: true,
    }
  },
  watch: {
    value(value) {
      if ((value === null) & this.clearUpdate) {
        this.clearDate()
      } else if (value && value !== null) {
        this.mergeDate()
      }
    },
  },
  methods: {
    mergeDate() {
      const date = dayjs(this.value).format('YYYY-MM-DD')
      console.log(date)
      this.$emit('update:modelValue', date)
      this.$emit('select')
    },
    clearDate() {
      this.clearUpdate = true
      this.oldValue = null
      this.value = null
      this.$emit('update:modelValue', '')
      this.$emit('select')
    },
    clearValueOnly() {
      this.clearUpdate = false
      this.oldValue = null
      this.value = null
    },
  },
}
</script>
