<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Promo</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Promo</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Promo"
          class="mr-2"
          @click="$router.push({ name: 'PromoAdd' })"
        />
      </div>
      <TabView class="tabview-custom" @tab-change="onTabChange">
        <TabPanel>
          <template #header>
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap">Table</span>
            </div>
          </template>
          <fx-table
            ref="table"
            :items="items"
            :loading="isLoading"
            :total="totalRecords"
            :grid-options="options"
            :filters="filters"
            @request="onRequestData"
            @sort="onSort($event)"
          >
            <template #columns>
              <Column style="min-width: 3rem; flex: initial" header="" expander>
              </Column>
              <Column
                field="kode"
                header="Kode Promo"
                style="min-width: 12rem"
                sortable
                :filter-match-mode-options="equalModesOption"
              >
                <template #filter="{ filterModel, filterCallback }">
                  <InputText
                    v-model="filterModel.value"
                    type="text"
                    class="p-column-filter"
                    placeholder="Search"
                    @keydown.enter="filterCallback()"
                  />
                </template>
              </Column>

              <Column
                field="start_at"
                header="Tgl Mulai"
                style="min-width: 12rem"
                sortable
                :showFilterMenu="false"
              >
                <template #body="{ data }">
                  {{ formatDate(data.start_at) }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                  <CalendarSingle
                    ref="calendarFilter"
                    v-model="filterModel.value"
                    @select="filterCallback()"
                  />
                </template>
              </Column>
              <Column
                field="end_at"
                header="Tgl Berakhir"
                style="min-width: 12rem"
                sortable
                :showFilterMenu="false"
              >
                <template #body="{ data }">
                  {{ formatDate(data.end_at) }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                  <CalendarSingle
                    ref="calendarFilterStop"
                    v-model="filterModel.value"
                    @select="filterCallback()"
                  />
                </template>
              </Column>
              <Column
                field="supplier.name"
                header="Supplier"
                sort-field="supp_name"
                filterField="supp_id"
                style="min-width: 12rem"
                :showFilterMenu="false"
                sortable
              >
                <template #filter="{ filterModel, filterCallback }">
                  <Dropdown
                    v-model="filterModel.value"
                    @change="filterCallback()"
                    :options="list_supplier"
                    :filter="true"
                    optionValue="id"
                    optionLabel="name"
                    placeholder="Pilih Supplier"
                    class="p-column-filter"
                    :showClear="true"
                  >
                  </Dropdown>
                </template>
              </Column>
              <Column
                field="status"
                header="status promo"
                style="min-width: 12rem"
                :showFilterMenu="false"
              >
                <template #body="{ data }">
                  <div
                    :class="'status-badge status-' + data.status"
                    style="text-transform: capitalize"
                  >
                    {{ data.status }}
                  </div>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                  <Dropdown
                    v-model="filterModel.value"
                    @change="filterCallback()"
                    :options="list_status"
                    optionValue="value"
                    optionLabel="label"
                    class="p-column-filter"
                  >
                  </Dropdown>
                </template>
              </Column>
              <Column
                header="Aktif"
                style="min-width: 6rem"
                header-style="justify-content: center"
              >
                <template #body="slotProps">
                  <div style="width: 100%; text-align: center">
                    <Tag
                      v-if="slotProps.data.active"
                      severity="success"
                      value="Aktif"
                    ></Tag>
                    <Tag
                      v-else
                      :style="{ background: 'grey' }"
                      value="Tidak Aktif"
                    ></Tag>
                  </div>
                </template>
              </Column>
              <Column
                field="deskripsi"
                header="Deskripsi"
                style="min-width: 18rem"
              >
              </Column>

              <Column style="flex-grow: 1; justify-content: flex-end">
                <template #body="{ data }">
                  <Button
                    type="button"
                    icon="pi pi-print"
                    class="p-button-outlined p-button-secondary p-button-text mr-2"
                    @click="onPrint(data)"
                  />
                  <Button
                    type="button"
                    icon="pi pi-pencil"
                    class="p-button-outlined p-button-secondary p-button-text mr-2"
                    @click="onEditData(data)"
                  />
                  <Button
                    type="button"
                    icon="pi pi-trash"
                    class="p-button-outlined p-button-danger p-button-text"
                    @click="onConfirmDeletion(data)"
                  />
                </template>
              </Column>
            </template>
          </fx-table>
        </TabPanel>
        <TabPanel>
          <template #header>
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap">Calendar</span>
            </div>
          </template>
          <div v-if="calendarTabVisible">
            <promo-calendar :items="this.items" />
          </div>
        </TabPanel>
      </TabView>
    </div>
    <Dialog
      :header="pdfTitle"
      v-model:visible="dialogViewer"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '90vw' }"
      :modal="true"
    >
      <div>
        <embed
          :src="pdfSource"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </div>
      <template #footer>
        <Button
          type="button"
          label="Download"
          icon="pi pi-download"
          class="p-button-text mr-2"
          @click="onDownloadPdf"
        />
        <Button
          type="button"
          label="Print"
          icon="pi pi-print"
          @click="onPrintPdf"
        />
      </template>
    </Dialog>
    <Dialog
      header="Hapus Promo"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Promo <strong>{{ form.deskripsi }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeletePromo"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import PromoService from '@/services/PromoService'
import SupplierService from '@/services/SupplierService'
import LaporanService from '@/services/LaporanService'
import FxTable from '@/components/promo/GridPromo'
import PromoCalendar from '@/views/promo/PromoCalendar'
import CalendarSingle from '@/components/CalendarSingle.vue'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import { formatDate } from '@/helpers/index'
import Tag from 'primevue/tag'

export default {
  name: 'Promo',
  components: {
    CalendarSingle,
    FxTable,
    Hotkey,
    Tag,
    PromoCalendar,
  },
  data() {
    return {
      promoService: null,
      dialogHapus: false,
      dialogItemDetailsVisible: false,
      isLoading: false,
      isLoadingSave: false,
      editMode: false,
      items: [],
      form: {},
      promo: [],
      list_supplier: [],
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: '',
        sortOrder: '1',
        filters: null,
      },
      list_status: [
        { value: 'planning', label: 'Planning', level: 0 },
        { value: 'ongoing', label: 'Ongoing', level: 1 },
        { value: 'expired', label: 'Expired', level: 2 },
      ],
      filters: {
        kode: { value: null, matchMode: FilterMatchMode.EQUALS },
        start_at: { value: null, matchMode: FilterMatchMode.CONTAINS },
        end_at: { value: null, matchMode: FilterMatchMode.CONTAINS },
        supp_id: { value: null, matchMode: FilterMatchMode.EQUALS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      calendarTabVisible: false,
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
      pdfSource: '',
      pdfFilename: '',
      pdfTitle: 'PDF Viewer ',
      dialogViewer: false,
    }
  },
  watch: {
    'options.filters'(value) {
      // custom Forcibly delete calendar data because it can't be deleted by default
      if (!value.start_at.value) {
        this.$refs.calendarFilter.clearValueOnly()
      }
      if (!value.end_at.value) {
        this.$refs.calendarFilterStop.clearValueOnly()
      }
    },
  },
  created() {
    this.promoService = new PromoService()
    this.supplierService = new SupplierService()
    this.laporanService = new LaporanService()
  },
  async mounted() {
    this.loadData()
    this.supplierService
      .get()
      .then((res) => {
        this.list_supplier = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Supplier', this)
      })
      .finally(() => (this.isLoading = false))
  },
  methods: {
    formatDate(value) {
      return formatDate(value, true)
    },
    formatDateToShort(value) {
      return value.split('T')[0]
    },
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.promoService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          res.data.sort((a, b) => a.status_label - b.status_label)

          this.items = res.data
          this.totalRecords = res.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data promo', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onSort(e) {
      this.options.value = e
      this.loadData()
    },
    onEditData(data) {
      this.$router.push({ name: 'PromoEdit', params: { id: data.id } })
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeletePromo() {
      this.promoService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 1) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Promo',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Promo', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onPrint(data) {
      this.laporanService
        .Promo(data.kode)
        .then((res) => {
          console.log(res)
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const pdfUrl = URL.createObjectURL(blob)
          this.pdfSource = pdfUrl + '#toolbar=0'
          this.pdfFilename = 'promo.pdf'
          this.pdfTitle = 'PDF Viewer (' + this.pdfFilename + ')'
          this.dialogViewer = true
        })
        .catch((err) => {
          errorHandler(err, 'Print Promo', this)
        })
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.$router.push({ name: 'PromoAdd' })
      }
    },
    onTabChange(e) {
      this.calendarTabVisible = e.index === 1
    },
  },
}
</script>

<style scoped>
::v-deep .p-tabview-panels {
  padding-left: 0 !important;
}
</style>
